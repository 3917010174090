import Head from "next/head";
import Layout from "../components/Layout";
import Flatpage from "../components/Flatpage";

const _404 = () => {
  return (
    <Layout noAds>
      <Head>
        <title>AppCheeta 404 - AppCheeta</title>
      </Head>
      <Flatpage center>
        <h1>Oops! We couldn't find that page</h1>

        <p>
          Please <a href="/contact">let us know</a> if you think this was an error
        </p>
      </Flatpage>
    </Layout>
  );
};

export default _404;
